import classNames from 'classnames';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { ReactComponent as CheckImage } from '../assets/check.svg';
import { ReactComponent as MinusImage } from '../assets/minus.svg';
import Button from '../components/Button';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import Switch from '../components/Switch';
import mixins from '../mixins.module.css';
import styles from './pricing.module.css';

const FEATURES = [
  'Portfolios',
  'Holdings',
  'Custom groups for holdings',
  'Latest and archived news for any ticker',
  'CSV import from multiple brokerages',
  'Real-time prices',
];

const PLANS = [
  {
    name: 'Standard',
    annualPrice: (
      <>
        <strong>$12</strong> per month
      </>
    ),
    monthlyPrice: (
      <>
        <strong>$15</strong> per month
      </>
    ),
    features: [
      <>1</>,
      <>Unlimited</>,
      <>Unlimited</>,
      <CheckImage aria-label="Yes" />,
      <CheckImage aria-label="Yes" />,
      <MinusImage aria-label="No" />,
    ],
  },
  {
    name: 'Pro',
    annualPrice: (
      <Button href="/contact" variant="primary">
        Contact us
      </Button>
    ),
    monthlyPrice: (
      <Button href="/contact" variant="primary">
        Contact us
      </Button>
    ),
    features: [
      <>Unlimited</>,
      <>Unlimited</>,
      <>Unlimited</>,
      <CheckImage aria-label="Yes" />,
      <CheckImage aria-label="Yes" />,
      <CheckImage aria-label="Yes" />,
    ],
  },
];

export default function PricingPage() {
  const [payAnnually, setPayAnnually] = useState(true);

  return (
    <Layout>
      <Helmet>
        <title>Wealthie — Pricing</title>
      </Helmet>
      <Hero title="Simple pricing">
        <p>No ads, no upselling.</p>
        <p>Try it out for free for 14 days. Cancel&nbsp;anytime.</p>
      </Hero>

      <div className={classNames(mixins.content, styles.wrapper)}>
        <table className={styles.pricingTable}>
          <thead>
            <tr>
              <td>
                <Switch
                  label="Pay annually"
                  onChange={(value) => {
                    setPayAnnually(value);
                  }}
                  size="large"
                  value={payAnnually}
                />
              </td>
              {PLANS.map(({ name, annualPrice, monthlyPrice }) => (
                <th key={name}>
                  <h3>{name}</h3>
                  <div className={styles.price}>
                    {payAnnually ? annualPrice : monthlyPrice}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {FEATURES.map((label, i) => (
              <tr key={label}>
                <th>{label}</th>
                {PLANS.map(({ name, features }) => (
                  <td key={name}>{features[i]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <div className={styles.pricingList}>
          <Switch
            label="Pay annually"
            onChange={(value) => {
              setPayAnnually(value);
            }}
            size="medium"
            value={payAnnually}
          />

          {PLANS.map(({ features, name, annualPrice, monthlyPrice }) => (
            <section key={name}>
              <header>
                <h3>{name}</h3>
                <div className={styles.price}>
                  {payAnnually ? annualPrice : monthlyPrice}
                </div>
              </header>
              <dl>
                {features.map((feature, i) => (
                  <div key={FEATURES[i]}>
                    <dt>{FEATURES[i]}</dt>
                    <dd>{feature}</dd>
                  </div>
                ))}
              </dl>
            </section>
          ))}
        </div>
      </div>
    </Layout>
  );
}
