import classNames from 'classnames';
import React from 'react';

import styles from './Switch.module.css';

interface SwitchProps {
  label: string;
  onChange: (value: boolean) => void;
  size?: 'medium' | 'large';
  value: boolean;
}

export default function Switch({
  label,
  onChange,
  size = 'medium',
  value,
}: SwitchProps) {
  return (
    <button
      aria-checked={value}
      className={classNames(styles.button, styles[`${size}Size`], {
        [styles.on]: value,
      })}
      onClick={() => {
        onChange(!value);
      }}
      role="switch"
      type="button"
    >
      <div className={styles.outer}>
        <div className={styles.inner} />
      </div>
      {label}
    </button>
  );
}
